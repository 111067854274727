import { useEffect } from 'react';
import './App.css';
import { isIOS, isAndroid } from 'react-device-detect';

function App() {

  useEffect(() => {
    if (isIOS) {
      window.location = 'instagram://user?username=kagitla';
    } else if (isAndroid) {
      window.location = 'intent://www.instagram.com/kagitla#Intent;package=com.instagram.android;scheme=https;end';
    } else {
      window.location = 'https://www.instagram.com/kagitla/';
    }
  }, []);

  return '';
}

export default App;
